*{
 margin: 0;
 padding: 0;

}

body{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: auto;
    margin-top: 1.5rem;
    width: 80%;
    /* height: 90%; */
    font-size: 20px;
    line-height: 1.8em;
}

/* Element customization */
#logo{
    margin: 1rem 0 0 0;
    min-width: 100px;
}
ul{
    border-radius: 1rem;
    padding: 0.3rem 1.3rem;
}

li{
    margin:0 1.3rem ;
    list-style: disc;
    color: rgb(88, 88, 88); 
}

a{
    color: rgb(88, 88, 88); 
    text-decoration: none;
}

/* Classes customization */
.policy-container{
    border-radius: 20px;
    margin: 0 1.5rem;    
}
.policy-title{
    text-align: center;
    margin: 4rem 0;
}
.policy-heading{
    margin: 1.5rem;   
    padding: 1rem;
    /* border: 1px solid black; */
    background-color: whitesmoke;
}

.Policy-text{
    color:rgb(66, 66, 66);
    padding: 0.3rem 1.3rem;
}

.policy-subheading{
    margin:0.7rem 1rem;
    /* color:#000000; */
}

.policy-date{
    
}

.list-heading{
    font-weight: bold;
}

ol li {
    list-style-type: decimal;
}

.policy-subList{
    list-style-type: circle;
}

.romanNumber-list{
    margin-left: 3rem;
}